.contact-container {
  min-height: 100vh;
  width: 100%;
  position: relative;
}

.contact-content {
  position: relative;
  z-index: 1;
  padding: 4rem 2rem;
  max-width: 800px;
  margin: 0 auto;
  color: #fff;
}

.contact-title {
  font-size: 3rem;
  margin-bottom: 2rem;
  text-align: center;
  background: linear-gradient(45deg, #4facfe, #00f2fe);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: fadeInDown 1s ease-out;
}

.contact-info {
  background: rgba(26, 26, 46, 0.8);
  padding: 2rem;
  border-radius: 15px;
  backdrop-filter: blur(10px);
  animation: fadeInUp 1s ease-out;
}

.contact-info p {
  text-align: center;
  margin-bottom: 2rem;
  color: #a8edea;
}

.contact-methods {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.contact-method {
  text-align: center;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.contact-method:hover {
  transform: translateY(-5px);
}

.contact-method h3 {
  color: #4facfe;
  margin-bottom: 1rem;
}

.contact-method a {
  color: #fed6e3;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-method a:hover {
  color: #4facfe;
}

@media (max-width: 768px) {
  .contact-title {
    font-size: 2.5rem;
  }
  
  .contact-content {
    padding: 2rem 1rem;
    padding-bottom: 5rem;
  }
  
  .contact-methods {
    grid-template-columns: 1fr;
    margin-bottom: 2rem;
  }

  .contact-container {
    min-height: auto;
    padding-bottom: 2rem;
  }

  .contact-info {
    padding: 1.5rem;
  }
} 