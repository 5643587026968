.cv-container {
  min-height: 100vh;
  width: 100%;
  position: relative;
}

.cv-content {
  position: relative;
  z-index: 1;
  padding: 4rem 2rem;
  max-width: 1000px;
  margin: 0 auto;
  color: #fff;
}

.cv-title {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 3rem;
  background: linear-gradient(45deg, #4facfe, #00f2fe);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: fadeInDown 1s ease-out;
}

.cv-section {
  background: rgba(26, 26, 46, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 2rem;
  margin-bottom: 2rem;
  animation: fadeInUp 1s ease-out;
}

.cv-section h2 {
  color: #4facfe;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
}

.timeline {
  position: relative;
}

.timeline-item {
  margin-bottom: 2rem;
  padding-left: 2rem;
  border-left: 2px solid #4facfe;
  position: relative;
}

.timeline-item::before {
  content: '';
  position: absolute;
  left: -8px;
  top: 0;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #4facfe;
}

.timeline-date {
  color: #00f2fe;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.timeline-content h3 {
  color: #a8edea;
  margin-bottom: 0.5rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

.company {
  color: #fed6e3;
  font-style: italic;
  margin-bottom: 0.5rem;
}

.skills-container {
  display: grid;
  gap: 2rem;
}

.skills-category h3 {
  color: #00f2fe;
  margin-bottom: 1rem;
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 1rem;
}

.skill-item {
  background: rgba(79, 172, 254, 0.1);
  border: 1px solid #4facfe;
  padding: 0.8rem;
  border-radius: 8px;
  text-align: center;
  transition: all 0.3s ease;
}

.skill-item:hover {
  transform: translateY(-3px);
  background: rgba(79, 172, 254, 0.2);
  box-shadow: 0 4px 12px rgba(79, 172, 254, 0.2);
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .cv-content {
    padding: 2rem 1rem;
  }

  .cv-title {
    font-size: 2.5rem;
  }

  .timeline-item {
    padding-left: 1.5rem;
  }

  .timeline-content h3 {
    font-size: 1.1rem;
    line-height: 1.4;
  }

  .skills-grid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
} 