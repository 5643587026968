.animated-bg {
  min-height: 100vh;
  background: linear-gradient(
    -45deg,
    #0a192f,
    #112240,
    #1a365d,
    #2d3748
  );
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  position: relative;
  overflow: hidden;
}

.animated-bg > *:not(canvas) {
  position: relative;
  z-index: 2;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.home-container {
  min-height: 100vh;
  width: 100%;
  position: relative;
}

.content {
  position: relative;
  z-index: 1;
  padding: 2rem;
}

.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  padding: 0 1rem;
}

.main-title {
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, #4facfe, #00f2fe);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: fadeInDown 1s ease-out;
}

.subtitle {
  font-size: 1.5rem;
  color: #a8edea;
  margin-bottom: 2rem;
  animation: fadeInUp 1s ease-out 0.3s both;
}

.description {
  max-width: 600px;
  margin: 0 auto 3rem;
  line-height: 1.8;
  color: #ffffff;
  font-size: 1.2rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  animation: fadeInUp 1s ease-out 0.6s both;
}

.nav-buttons {
  display: flex;
  gap: 1.5rem;
  animation: fadeInUp 1s ease-out 0.9s both;
}

.nav-button {
  padding: 1rem 2rem;
  border-radius: 30px;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
  border: 2px solid transparent;
}

.nav-button:first-child {
  background: linear-gradient(45deg, #4facfe, #00f2fe);
  color: #1a1a2e;
}

.nav-button.secondary {
  border: 2px solid #4facfe;
  color: #4facfe;
}

.nav-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(79, 172, 254, 0.3);
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .main-title {
    font-size: 3rem;
  }
  
  .subtitle {
    font-size: 1.2rem;
  }
  
  .description {
    font-size: 1.1rem;
    line-height: 1.6;
    padding: 0 0.5rem;
    max-width: 100%;
    margin: 0 auto 2rem;
  }
  
  .nav-buttons {
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    margin-bottom: 5rem;
  }

  .content {
    padding: 1rem;
    padding-bottom: 2rem;
  }

  .hero-section {
    padding: 0 0.5rem;
    min-height: auto;
    padding-bottom: 2rem;
  }

  .home-container {
    min-height: auto;
    padding-bottom: 2rem;
  }
} 